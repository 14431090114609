import React from "react";

function Scan() {
  return (
    <div className="relative w-screen h-screen">
      <img
        src="/images/background-3.jpg"
        className="hidden md:block w-full h-full object-cover"
        alt="Desktop Background"
      />

      <div className="absolute inset-x-0 top-0 h-[50px] bg-gradient-to-b from-[#271e1f] to-transparent"></div>

      <div className="absolute inset-x-0 bottom-0 h-[50px] bg-gradient-to-t from-[#271e1f] to-transparent"></div>

      <img
        src="/images/mobile/background-3.png"
        className="block md:hidden w-full h-full object-cover"
        alt="Mobile Background"
      />

      <div className="mt-0 absolute inset-0 flex items-center justify-end px-4 sm:px-8 md:px-12 lg:px-16 hidden sm:block">
        <div className="mt-10 sm:mt-16 md:mt-32 container mx-auto text-right text-theme">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2">
            SCAN TO
          </h1>
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
            DOWNLOAD
          </h1>
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
            TRIP ME APP
          </h1>
          <p className="text-base sm:text-lg md:text-xl leading-relaxed text-white">
            TripMe stands out by combining personalized
          </p>
          <p className="text-base sm:text-lg md:text-xl leading-relaxed text-white">
            travel experiences with cutting-edge technology,
          </p>
          <p className="text-base sm:text-lg md:text-xl leading-relaxed text-white">
            all under the trusted umbrella of
          </p>
          <p className="text-base sm:text-lg md:text-xl leading-relaxed text-white">
            Trip Me Ceylon (Private) Limited.
          </p>
        </div>
      </div>

      <div className="absolute inset-0 flex items-start justify-start px-4 sm:px-8 md:px-16 md:mt-32 top-0 w-full block sm:hidden">
        <div className="p-5 mt-16 container mx-auto text-left text-theme">
          <h1 className="text-4xl font-bold mb-2">SCAN TO</h1>
          <h1 className="text-4xl font-bold mb-4">DOWNLOAD</h1>
          <h1 className="text-3xl font-bold mb-4">TRIP ME APP</h1>
          <p className="text-lg leading-relaxed text-white">
            TripMe stands out by combining personalized travel experiences with
            cutting-edge technology, all under the trusted umbrella of Trip Me
            Ceylon (Private) Limited.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Scan;
