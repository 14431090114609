import React, { useEffect, useState } from "react";
import Footer from "../../components/home/footer";
import { Link } from "react-router-dom";

function AboutPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <div className="container">
      <div className="w-screen h-screen relative">
        <nav className="bg-black border-gray-200 dark:bg-gray-900 absolute top-0 left-0 right-0 z-50 w-full">
          <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4 py-4">
            <div className="flex items-center">
              <a
                href="https://partner.tripme.lk/sign-in"
                className="hidden md:inline-block"
              >
                <button
                  type="button"
                  className="text-white bg-theme hover:bg-theme focus:ring-4 focus:outline-none focus:ring-theme font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transform hover:scale-105 transition-transform duration-200"
                >
                  Become a Partner
                </button>
              </a>
            </div>

            <Link
              to="https://www.tripme.lk/"
              className="flex items-center justify-center"
            >
              <img
                src="/images/logo-2.png"
                className="h-16 w-auto"
                alt="Logo"
              />
            </Link>

            <div className="mr-10 hidden md:flex space-x-4 items-center">
              {["facebook", "instagram", "tiktok"].map((icon) => (
                <Link
                  key={icon}
                  to={`https://www.${icon}.com`}
                  className="hover:scale-110 transition-transform duration-300"
                >
                  <img
                    src={`/images/icons/${icon}.png`}
                    className="h-7 w-auto"
                    alt={icon}
                  />
                </Link>
              ))}
            </div>

            <button
              type="button"
              className="md:hidden inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

          {isMenuOpen && (
            <div className="md:hidden bg-white z-100 shadow-lg mt-2 p-4">
              <ul className="space-y-4 text-gray-800">
                <li>
                  <Link to="/" className="block text-lg hover:text-theme">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="block text-lg hover:text-theme">
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/refund-policy"
                    className="block text-lg hover:text-theme "
                  >
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="block text-lg hover:text-theme"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    className="block text-lg hover:text-theme"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://partner.tripme.lk/sign-in"
                    className="block text-lg hover:text-theme text-theme"
                  >
                    Become a partner
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </nav>

        <div className="pt-16 pb-5 flex flex-col items-center justify-center">
          <img
            src="/images/About.png"
            alt="Description of the image"
            className="w-full max-w-full shadow-lg"
          />

          <div className="bg-gray-800 text-white py-5 px-4 sm:px-6 lg:px-12">
            <div className="container mx-auto p-5 text-center sm:text-left">
              <h2 className="text-3xl font-semibold mb-4 mt-2">About Us</h2>
              <p className="text-lg mb-8">
                Welcome to TripMe.lk, your ultimate travel partner for
                discovering the vibrant beauty of Sri Lanka. Guided by our
                mission to provide seamless, enriching, and memorable travel
                experiences, we connect travelers with the wonders of Sri Lanka
                through customized, immersive tours and exceptional service.
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="mt-5 text-3xl font-semibold mb-4">
                    Our Mission
                  </h3>
                  <p className="text-lg">
                    At TripMe.lk, we believe travel should be more than just
                    sightseeing; it should be a journey of connection and
                    discovery. Our mission is to showcase the true essence of
                    Sri Lanka by offering unique, authentic experiences and
                    exceptional customer service. We are dedicated to promoting
                    sustainable tourism and creating positive impacts on the
                    communities we explore.
                  </p>
                </div>

                <div className="flex justify-center items-center">
                  <img
                    src="images/about-2.png"
                    alt="Mission Illustration"
                    className="w-auto h-auto max-w-xs"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="lg:w-1/2 w-full p-5">
              <img
                src="images/about-3.png"
                alt="Services Illustration"
                className="w-full h-auto"
              />
            </div>
            <div className="p-5 lg:w-full w-full mt-2 lg:mt-0 text-left ">
              <h2 className="text-3xl font-bold mb-4">Our Services</h2>
              <ul className="mb-5 space-y-2 text-lg">
                <li>
                  • Curated travel packages featuring Sri Lanka’s cultural and
                  natural attractions
                </li>
                <li>
                  • Personalized itineraries tailored to individual preferences
                </li>
                <li>• Expert local guides to enrich your travel experience</li>
                <li>
                  • Secure booking and dedicated customer support throughout
                  your journey
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full bg-gray-900 text-white py-10">
            <div className="max-w-7xl mx-auto px-2 md:px-20">
              <div className="p-5 text-center mb-5">
                <h2 className="text-3xl font-bold mb-4 shadow-lg">
                  Our <span className="text-white">Story</span>
                </h2>
                <p className="text-lg leading-relaxed max-w-4xl mx-auto">
                  At Tripme, we believe that travel should be more than just
                  sightseeing; it should be a journey of connection and
                  discovery. Our mission is to showcase the true essence of Sri
                  Lanka by offering unique, authentic experiences and
                  exceptional customer service. We are dedicated to promoting
                  sustainable tourism and creating positive impacts on the
                  communities we explore.
                </p>
              </div>

              <div className="p-5 flex items-center justify-center">
                <div className="text-center">
                  <h3 className=" text-3xl font-bold shadow-lg">
                    Why Choose Us?
                  </h3>
                  <ul className="mt-5 text-lg leading-relaxed max-w-4xl mx-auto text-left list-disc list-inside">
                    <li>
                      Renowned for personalized service and deeply authentic
                      experiences.
                    </li>
                    <li>
                      Locally-based team with extensive knowledge of Sri Lanka’s
                      best destinations.
                    </li>
                    <li>
                      Commitment to sustainable tourism and community support.
                    </li>
                    <li>
                      Dedicated customer care before, during, and after your
                      travels.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
