import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import Scan from "./components/home/scan";
import About from "./components/home/about";
import AppDownload from "./components/home/appdetails";
import Footer_Header from "./components/home/adventure";
import Footer from "./components/home/footer";
import { Home } from "lucide-react";
import HomePage from "./App/home/page";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
    
    
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
