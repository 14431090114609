import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Hero() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    
    document.body.style.overflowX = "hidden";
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  const socialIcons = ["facebook", "instagram", "tiktok"];

  const menuItems = [
    { to: "/", label: "Home" },
    { to: "/about", label: "About" },
    { to: "/refund-policy", label: "Refund Policy" },
    { to: "/privacy-policy", label: "Privacy Policy" },
    { to: "/terms-and-conditions", label: "Terms & Conditions" },
    {
      to: "https://partner.tripme.lk/sign-in",
      label: "Become a Partner",
      external: true,
    },
  ];

  return (
    <div className="container">
      <div className="w-screen h-screen relative">
        <div className="absolute inset-0">
          <img
            src="/images/background-1.jpg"
            className="hidden md:block w-full h-full object-cover"
            alt="Desktop Background"
          />
          <div className="absolute inset-x-0 bottom-0 h-[50px] bg-gradient-to-t from-[#271e1f] to-transparent"></div>

          <img
            src="/images/mobile/background-1.jpg"
            className=" block md:hidden w-full h-auto object-cover"
            alt="Mobile Background"
          />
        </div>

        <nav className="bg-black bg-opacity-30 border-gray-200 absolute top-2 left-0 right-0 z-50">
          <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4">
            <div className="flex items-center">
              <a
                href="https://partner.tripme.lk/sign-in"
                className="hidden md:inline-block"
              >
                <button
                  type="button"
                  className="text-white bg-transparent hover:bg-theme focus:ring-4 focus:outline-none focus:ring-theme font-medium rounded-lg text-sm px-5 py-2.5"
                >
                  Become a Partner
                </button>
              </a>
            </div>

           
            <a
              href="https://www.tripme.lk/"
              className="flex items-center justify-center"
            >
              <img
                src="/images/logo-2.png"
                className="h-16 w-auto"
                alt="Logo"
              />
            </a>

           
            <div className="mr-10 hidden md:flex space-x-4 items-center">
              {socialIcons.map((icon) => (
                <a
                  key={icon}
                  href={`https://www.${icon}.com`}
                  className="hover:scale-110 transition-transform duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`/images/icons/${icon}.png`}
                    className="h-7 w-auto"
                    alt={`${icon} Icon`}
                  />
                </a>
              ))}
            </div>

           
            <button
              type="button"
              className="md:hidden inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

         
          {isMenuOpen && (
            <div className="md:hidden bg-white shadow-lg mt-2 p-4">
              <ul className="space-y-4 text-gray-800">
                {menuItems.map(({ to, label, external }) => (
                  <li key={label}>
                    {external ? (
                      <a
                        href={to}
                        className="block text-lg hover:text-theme text-theme"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {label}
                      </a>
                    ) : (
                      <Link
                        to={to}
                        className="block text-lg hover:text-theme"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        {label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </nav>

        
        <div className="container mx-auto px-4 hidden md:flex items-center justify-start text-white absolute inset-0">
          <div className="ml-10">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4">
              READY TO <br />
              PLAN YOUR <br />
              <span className="font-serif font-light">Next Dream Trip?</span>
            </h1>
            <button className="mt-5 bg-theme text-white py-2 px-6 rounded-lg text-lg font-semibold hover:bg-orange-600 transition duration-300 drop-shadow-2xl">
              Download Now
            </button>
          </div>
        </div>

        
        <div className="mt-20 z-1 absolute top-0 left-0 w-screen flex items-start justify-start text-white px-2 block md:hidden">
          <div className="mt-10">
            <h1 className="ml-10 text-4xl font-bold mb-4">
              READY TO <br />
              <span className="text-[#FFDFD2]">PLAN YOUR</span> <br />
              Next Dream Trip?
            </h1>
            <button className="ml-10 mt-5 bg-theme text-white py-2 px-6 rounded-lg text-lg font-semibold hover:bg-orange-600 transition duration-300 drop-shadow-2xl">
              Download Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
