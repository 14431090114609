import React from "react";

function Footer_Header() {
  return (
    <div className="relative w-full h-1/2 sm:h-3/4 md:h-1/2 lg:h-1/2">
      <img
    src="/images/background-5.jpg"
    className="w-full h-full object-cover"
    alt="Background"
  />
  <div className="absolute top-0 left-0 w-full h-20 bg-gradient-to-b from-[#271e1f] to-transparent"></div>
  <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-black to-transparent"></div>


<div className="absolute inset-0 flex flex-col items-center justify-center px-4 sm:px-8 md:px-16 text-white">
  <div className="flex space-x-4 mb-4 text-center px-4">
    <h1 className="text-2xl sm:text-4xl font-bold mb-1">ADVENTURE AWAITS.</h1>
  </div>

  <div className="flex space-x-4 text-center px-4">
    <p className="text-base sm:text-xl font-semibold mb-6">
      Let Trip Me Help You Get There With TripMe!
    </p>
  </div>
</div>

    </div>
  );
}

export default Footer_Header;
