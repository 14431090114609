import React, { useEffect, useState } from "react";
import Footer from "../../components/home/footer";
import { Link } from "react-router-dom";

function ReturnPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
   
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <div className="container">
      <div className="w-screen h-screen relative">
        
        <nav className="bg-black border-gray-200 dark:bg-gray-900 absolute top-0 left-0 right-0 z-50 w-full">
          <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4 py-4">
          
            <div className="flex items-center">
              <Link
                to="https://partner.tripme.lk/sign-in"
                className="hidden md:inline-block"
              >
                <button
                  type="button"
                  className="text-white bg-theme hover:bg-theme focus:ring-4 focus:outline-none focus:ring-theme font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transform hover:scale-105 transition-transform duration-200"
                >
                  Become a Partner
                </button>
              </Link>
            </div>

           
            <Link
              to="https://www.tripme.lk/"
              className="flex items-center justify-center"
            >
              <img
                src="/images/logo-2.png"
                className="h-16 w-auto"
                alt="Logo"
              />
            </Link>

           
            <div className="mr-10 hidden md:flex space-x-4 items-center">
              {["facebook", "instagram", "tiktok"].map((icon) => (
                <Link
                  key={icon}
                  to={`https://www.${icon}.com`}
                  className="hover:scale-110 transition-transform duration-300"
                >
                  <img
                    src={`/images/icons/${icon}.png`}
                    className="h-7 w-auto"
                    alt={icon}
                  />
                </Link>
              ))}
            </div>

           
            <button
              type="button"
              className="md:hidden inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

          
          {isMenuOpen && (
           <div className="md:hidden bg-white z-100 shadow-lg mt-2 p-4">
           <ul className="space-y-4 text-gray-800">
             <li>
               <Link to="/" className="block text-lg hover:text-theme">
                 Home
               </Link>
             </li>
             <li>
               <Link to="/about" className="block text-lg hover:text-theme">
                 About
               </Link>
             </li>
             <li>
               <Link
                 to="/refund-policy"
                 className="block text-lg hover:text-theme "
               >
                 Refund Policy
               </Link>
             </li>
             <li>
               <Link
                 to="/privacy-policy"
                 className="block text-lg hover:text-theme"
               >
                 Privacy Policy
               </Link>
             </li>
             <li>
               <Link
                 to="/terms-and-conditions"
                 className="block text-lg hover:text-theme"
               >
                 Terms & Conditions
               </Link>
             </li>
             <li>
               <Link
                 to="https://partner.tripme.lk/sign-in"
                 className="block text-lg hover:text-theme text-theme"
               >
                 Become a partner
               </Link>
             </li>
           </ul>
         </div>
          )}
        </nav>

       
        <div className="pt-16 pb-5 flex flex-col items-center justify-center">
         
          <img
            src="/images/img2.png"
            alt="Description of the image"
            className="w-full max-w-fit  shadow-lg"
          />

          <div className="p-10  text-left text-lg text-gray-800 space-y-6">
            <p className="text-4xl font-semibold text-center">
              <strong>Refund Policy</strong>
            </p>
            <p>
              Thank you for booking an event with Tripme.lk. We value your
              participation and aim to ensure the best event experience for all
              attendees. If, for any reason, you are unable to attend or need to
              cancel your booking, we are here to help.
            </p>

            <h3 className="font-bold">Event Cancellations</h3>
            <p>
              We accept cancellations for events within 7 days from the date of
              booking. To be eligible for a full refund, your event booking must
              be canceled within the specified period. After the 7-day window,
              the refund may be subject to event-specific terms.
            </p>

            <h3 className="font-bold">Refunds</h3>
            <p>
              Once we receive your cancellation request, we will process your
              refund. If your cancellation is approved, we will initiate a
              refund to your original payment method. Please note that any
              service charges, booking fees, or additional expenses (if
              applicable) may be deducted from the refund amount.
            </p>

            <h3 className="font-bold">Exchanges</h3>
            <p>
              Unfortunately, we do not offer exchanges for event bookings. If
              you are unable to attend the booked event, we recommend canceling
              and rebooking for another session if applicable.
            </p>

            <h3 className="font-bold">Non-Refundable Events</h3>
            <p>Certain events or bookings are non-refundable. These include:</p>
            <ul className="list-disc pl-5">
              <li>Special events with limited availability</li>
              <li>Prepaid non-refundable bookings</li>
              <li>Non-transferable tickets</li>
            </ul>

            <h3 className="font-bold">Damaged or Defective Events</h3>
            <p>
              In the unfortunate event that the event or experience itself is
              canceled due to factors beyond our control, such as weather or
              logistical issues, we will either reschedule the event or offer a
              full refund. Please contact us immediately in case of such
              circumstances.
            </p>

            <h3 className="font-bold">Cancellations by Us</h3>
            <p>
              If we cancel the event for any reason, you will be notified
              immediately and offered a full refund or the option to reschedule
              for another available date.
            </p>

            <h3 className="font-bold">Contact Us</h3>
            <p>
              If you have any questions or concerns regarding our event refund
              policy, please contact our customer support team. We are here to
              assist you and ensure that your experience with us is smooth and
              enjoyable.
            </p>
          </div>

          <div className="mt-6 text-center">
            <p>© 2024 Tripme.lk. All rights reserved.</p>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ReturnPage;
