import React from "react";

function About() {
  return (
    <div className=" relative w-screen h-screen">
      <div className="absolute inset-0">
      <img
    src="/images/background-2.jpg"
    className="hidden md:block w-full h-full object-cover"
    alt="Background"
  />
  
  <div className="absolute inset-x-0 top-0 h-[100px] bg-gradient-to-b from-[#271e1f] to-transparent"></div>
  
  <div className="absolute inset-x-0 bottom-0 h-[100px] bg-gradient-to-t from-[#271e1f] to-transparent"></div>

</div>

      <img
        src="/images/mobile/background-2.png"
        className="block md:hidden w-full h-full object-cover"
        alt="Mobile Background"
      />

      <div className="absolute inset-0 flex items-center justify-center hidden md:flex">
        <div className="container mx-auto text-left text-theme px-4">
          <h1 className="text-5xl font-bold mb-2 drop-shadow-2xl">ABOUT</h1>
          <h1 className="text-5xl font-bold mb-4 drop-shadow-2xl">TRIP ME</h1>
          <p className="text-xl leading-relaxed text-white drop-shadow-2xl">
            Trip Me is Your Ultimate Travel Companion
          </p>
          <p className="text-xl leading-relaxed text-white drop-shadow-2xl">
            offering Seamless Hotel Booking, Thrilling
          </p>
          <p className="text-xl leading-relaxed text-white drop-shadow-2xl">
            Helicopter Rides, Activity Planning & Trip Discovery.
          </p>
          <p className="text-xl leading-relaxed text-white drop-shadow-2xl">
            Explore, Book and Experience Unforgettable Adventure with Ease !
          </p>
        </div>
      </div>

      <div className="mt-10 p-10 md:p-10 absolute inset-x-0 top-0 flex items-center justify-center block md:hidden">
        <div className="container mx-auto px-4">
          <div className="text-left text-theme">
            <h1 className="text-3xl md:text-5xl font-bold mb-2 drop-shadow-2xl">
              ABOUT
            </h1>
            <h1 className="text-3xl md:text-5xl font-bold mb-4 drop-shadow-2xl">
              TRIP ME
            </h1>
            <p className="max-w-full sm:max-w-screen-sm text-base sm:text-lg md:text-xl leading-relaxed text-white drop-shadow-2xl mb-4">
              Trip Me is Your Ultimate Travel Companion offering Seamless Hotel
              Booking, Thrilling Helicopter Rides, Activity Planning & Trip
              Discovery. Explore, Book and Experience Unforgettable Adventure
              with Ease!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
