import React, { useEffect, useState } from "react";
import Footer from "../../components/home/footer";
import { Link } from "react-router-dom";

function TermsPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
   
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <div className="container">
      <div className="w-screen h-screen relative">
      
        <nav className="bg-black border-gray-200 dark:bg-gray-900 absolute top-0 left-0 right-0 z-50 w-full">
          <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4 py-4">
          
            <div className="flex items-center">
              <Link
                to="https://partner.tripme.lk/sign-in"
                className="hidden md:inline-block"
              >
                <button
                  type="button"
                  className="text-white bg-theme hover:bg-theme focus:ring-4 focus:outline-none focus:ring-theme font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transform hover:scale-105 transition-transform duration-200"
                >
                  Become a Partner
                </button>
              </Link>
            </div>

           
            <Link
              to="https://www.tripme.lk/"
              className="flex items-center justify-center"
            >
              <img
                src="/images/logo-2.png"
                className="h-16 w-auto"
                alt="Logo"
              />
            </Link>

            
            <div className="mr-10 hidden md:flex space-x-4 items-center">
              {["facebook", "instagram", "tiktok"].map((icon) => (
                <Link
                  key={icon}
                  to={`https://www.${icon}.com`}
                  className="hover:scale-110 transition-transform duration-300"
                >
                  <img
                    src={`/images/icons/${icon}.png`}
                    className="h-7 w-auto"
                    alt={icon}
                  />
                </Link>
              ))}
            </div>

           
            <button
              type="button"
              className="md:hidden inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

          
          {isMenuOpen && (
           <div className="md:hidden bg-white z-100 shadow-lg mt-2 p-4">
           <ul className="space-y-4 text-gray-800">
             <li>
               <Link to="/" className="block text-lg hover:text-theme">
                 Home
               </Link>
             </li>
             <li>
               <Link to="/about" className="block text-lg hover:text-theme">
                 About
               </Link>
             </li>
             <li>
               <Link
                 to="/refund-policy"
                 className="block text-lg hover:text-theme "
               >
                 Refund Policy
               </Link>
             </li>
             <li>
               <Link
                 to="/privacy-policy"
                 className="block text-lg hover:text-theme"
               >
                 Privacy Policy
               </Link>
             </li>
             <li>
               <Link
                 to="/terms-and-conditions"
                 className="block text-lg hover:text-theme"
               >
                 Terms & Conditions
               </Link>
             </li>
             <li>
               <Link
                 to="https://partner.tripme.lk/sign-in"
                 className="block text-lg hover:text-theme text-theme"
               >
                 Become a partner
               </Link>
             </li>
           </ul>
         </div>
          )}
        </nav>

        
        <div className="pt-16 pb-5 flex flex-col items-center justify-center">
        
          <img
            src="/images/img3.png"
            alt="Description of the image"
            className="w-full max-w-fit shadow-lg"
          />

          <div className="p-10 text-left text-lg text-gray-800 space-y-6">
            <p className="text-4xl font-semibold text-center">
              <strong>Terms and Conditions</strong>
            </p>
            <p>
              Welcome to TripMe.lk. These Terms and Conditions govern your use
              of our website and the booking of events, tours, and experiences
              through our platform. By accessing and using our website, you
              agree to comply with these terms. Please read them carefully before
              proceeding with any transactions.
            </p>

            <h3 className="font-bold">Use of the Website</h3>
            <p>
              You must be at least 18 years old to use our website or book
              events. You are responsible for maintaining the confidentiality of
              your account information, including your username and password.
              You agree to provide accurate and current information during the
              registration and booking process. You may not use our website for
              any unlawful or unauthorized purposes.
            </p>

            <h3 className="font-bold">Event Information and Pricing</h3>
            <p>
              We strive to provide accurate event descriptions, dates, and
              pricing information. However, we do not guarantee the accuracy or
              completeness of such information. Prices for events, tours, and
              experiences are subject to change without notice. Any promotions or
              discounts are valid for a limited time and may be subject to
              additional terms and conditions.
            </p>

            <h3 className="font-bold">Bookings and Payments</h3>
            <p>
              By making a booking on our website, you are confirming your
              intention to participate in the selected event or tour. We reserve
              the right to refuse or cancel any booking for any reason, including
              but not limited to event availability, errors in pricing or event
              details, or suspected fraudulent activity.
            </p>

            <h3 className="font-bold">Event Availability and Cancellation</h3>
            <p>
              We will make reasonable efforts to ensure that events are held as
              scheduled. Event schedules, availability, and timings may be subject
              to change due to unforeseen circumstances. In case of event
              cancellations, you will be notified, and refunds will be provided
              as per our refund policy.
            </p>

            <h3 className="font-bold">Returns and Refunds</h3>
            <p>
              Our Refund Policy governs the process and conditions for returning
              payments or seeking refunds for cancelled or modified bookings. Please
              refer to the policy provided on our website for more details.
            </p>

            <h3 className="font-bold">Intellectual Property</h3>
            <p>
              All content and materials on our website, including but not limited
              to text, images, logos, and graphics, are protected by intellectual
              property rights and are the property of TripMe.lk or its licensors.
              You may not use, reproduce, distribute, or modify any content from
              our website without our prior written consent.
            </p>

            <h3 className="font-bold">Limitation of Liability</h3>
            <p>
              In no event shall TripMe.lk, its directors, employees, or affiliates
              be liable for any direct, indirect, incidental, special, or
              consequential damages arising out of or in connection with your use
              of our website or the booking and participation in our events.
            </p>

            <h3 className="font-bold">Amendments and Termination</h3>
            <p>
              We reserve the right to modify, update, or terminate these Terms
              and Conditions at any time without prior notice. It is your
              responsibility to review these terms periodically for any changes.
            </p>

            <div className="mt-6 text-center">
              <p>© 2024 TripMe.lk. All rights reserved.</p>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default TermsPage;
