import React, { useEffect, useState } from "react";
import Footer from "../../components/home/footer";
import { Link } from "react-router-dom";

function PrivacyPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Disable horizontal scroll globally
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <div className="container">
      <div className="w-screen h-screen relative">
        {/* Navigation bar */}
        <nav className="bg-black border-gray-200 dark:bg-gray-900 absolute top-0 left-0 right-0 z-50 w-full">
          <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4 py-4">
            {/* Become a Partner Button */}
            <div className="flex items-center">
              <a
                href="https://partner.tripme.lk/sign-in"
                className="hidden md:inline-block"
              >
                <button
                  type="button"
                  className="text-white bg-theme hover:bg-theme focus:ring-4 focus:outline-none focus:ring-theme font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transform hover:scale-105 transition-transform duration-200"
                >
                  Become a Partner
                </button>
              </a>
            </div>

            {/* Logo */}
            <a
              href="https://www.tripme.lk/"
              className="flex items-center justify-center"
            >
              <img
                src="/images/logo-2.png"
                className="h-16 w-auto"
                alt="Logo"
              />
            </a>

            {/* Social media icons */}
            <div className="mr-10 hidden md:flex space-x-4 items-center">
              {["facebook", "instagram", "tiktok"].map((icon) => (
                <a
                  key={icon}
                  href={`https://www.${icon}.com`}
                  className="hover:scale-110 transition-transform duration-300"
                >
                  <img
                    src={`/images/icons/${icon}.png`}
                    className="h-7 w-auto"
                    alt={icon}
                  />
                </a>
              ))}
            </div>

            {/* Hamburger menu */}
            <button
              type="button"
              className="md:hidden inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

         
          {isMenuOpen && (
           <div className="md:hidden bg-white z-100 shadow-lg mt-2 p-4">
           <ul className="space-y-4 text-gray-800">
             <li>
               <Link to="/" className="block text-lg hover:text-theme">
                 Home
               </Link>
             </li>
             <li>
               <Link to="/about" className="block text-lg hover:text-theme">
                 About
               </Link>
             </li>
             <li>
               <Link
                 to="/refund-policy"
                 className="block text-lg hover:text-theme "
               >
                 Refund Policy
               </Link>
             </li>
             <li>
               <Link
                 to="/privacy-policy"
                 className="block text-lg hover:text-theme"
               >
                 Privacy Policy
               </Link>
             </li>
             <li>
               <Link
                 to="/terms-and-conditions"
                 className="block text-lg hover:text-theme"
               >
                 Terms & Conditions
               </Link>
             </li>
             <li>
               <Link
                 to="https://partner.tripme.lk/sign-in"
                 className="block text-lg hover:text-theme text-theme"
               >
                 Become a partner
               </Link>
             </li>
           </ul>
         </div>
          )}
        </nav>

        
        <div className="pt-16 pb-5 flex flex-col items-center justify-center">
          
          <img
            src="/images/img1.png"
            alt="Description of the image"
            className="w-full max-w-fit shadow-lg"
          />

          <div className="p-10 text-left text-lg text-gray-800 space-y-6">
            <p className="text-4xl font-semibold text-center">
              <strong>Privacy Policy</strong>
            </p>
            <p>
              At TripMe.lk, we are committed to protecting the privacy and security
              of the personal information of our event attendees. This Privacy
              Policy explains how we collect, use, and protect your information when
              you register for or attend an event hosted by us. By registering for or
              attending our events, you agree to the practices outlined in this policy.
            </p>

            <h3 className="font-bold">Information We Collect</h3>
            <p>
              When you register for an event, we may collect the following information:
            </p>
            <ul className="list-disc pl-6">
              <li>Personal identification information (such as your name, email address, and phone number) provided voluntarily during event registration.</li>
              <li>Payment information (if applicable) to process event fees, including credit card details, which are securely handled by trusted third-party payment processors.</li>
              <li>Event-related preferences and specific interests provided by you during the registration process.</li>
              <li>Browsing and device information, such as IP address, browser type, and device used to access our website or event platform, which may be collected automatically using cookies.</li>
            </ul>

            <h3 className="font-bold">How We Use Your Information</h3>
            <p>We may use the collected information for the following purposes:</p>
            <ul className="list-disc pl-6">
              <li>To process your event registration and manage event-related communications.</li>
              <li>To send event updates, reminders, and other relevant communications.</li>
              <li>To personalize event content and recommendations based on your preferences and interests.</li>
              <li>To improve the event experience by analyzing feedback and attendee behavior.</li>
              <li>To ensure the security and safety of all attendees at the event.</li>
            </ul>

            <h3 className="font-bold">Sharing Your Information</h3>
            <p>
              We respect your privacy and do not sell, trade, or share your personal
              information with third parties for marketing purposes without your consent.
              However, we may share your information under the following circumstances:
            </p>
            <ul className="list-disc pl-6">
              <li>Event Partners: We may share your information with trusted partners or vendors who help organize or facilitate the event, such as sponsors or service providers.</li>
              <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid legal requests or orders.</li>
            </ul>

            <h3 className="font-bold">Data Security</h3>
            <p>
              We take reasonable precautions to protect your personal information from
              unauthorized access, alteration, disclosure, or destruction. However, no
              method of transmission over the internet or electronic storage is fully
              secure, and we cannot guarantee absolute security.
            </p>

            <h3 className="font-bold">Cookies and Tracking Technologies</h3>
            <p>
              We use cookies and similar technologies to enhance your browsing
              experience and to collect data about your interactions with our event
              registration platform. You can disable cookies in your browser settings,
              but this may limit certain features or functionality of our website or
              event platform.
            </p>

            <h3 className="font-bold">Changes to the Privacy Policy</h3>
            <p>
              We reserve the right to update this Privacy Policy at any time. Any
              changes will be posted on this page, and the "last updated" date will
              be revised. We encourage you to review this policy periodically to stay
              informed about how we collect, use, and protect your information.
            </p>

            <h3 className="font-bold">Contact Us</h3>
            <p>
              If you have any questions, concerns, or requests regarding this Privacy
              Policy or your personal information, please contact us using the
              information provided on our website.
            </p>

            <div className="mt-6 text-center">
              <p>© 2024 TripMe.lk. All rights reserved.</p>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
